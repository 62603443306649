.subscribe_form_main {
    padding: 2.5625vw;
    width: 33.25vw;
    margin: 0 auto;
    float: inherit;
    text-align: center;
    padding-bottom: 3.5625vw;
    background: rgb(111,15,49);
    background: linear-gradient(311deg, rgba(111,15,49,1) 0%, rgba(2,57,143,1) 100%);
}

.subscribe_check {
    width: 2.604166vw;
    height: 2.604166vw;
    background: #15d24a;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 1.041666vw;
    color: #fff;
}

.subscribe_failed {
    width: 2.604166vw;
    height: 2.604166vw;
    background: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 1.041666vw;
    color: #fff;
}

.subscribe_form_main h2 {
    font-size: 1.5625vw;
    margin-top: 1.302083vw;
    display: inline-block;
    margin-bottom: 0;
    font-weight: 600;
    color: #fff;
}


.cntent_subscribe {
    margin-top: 1.5625vw;
    display: flex;
    flex-wrap: wrap;
    gap: 0.260416vw;
}



.cntent_subscribe p {
    font-size: 0.833333vw;
    color: #fff;
    width: 100%;
}

.subscribe_btn{display: block; text-align: center; margin-top: 2.083333vw;}
.subscribe_btn a {
    display: inline-block;
    position: relative;
    background: #1c77ff;
    text-decoration: none;
    color: #fff;
    padding: 0.416666vw 3.645833vw;
    font-size: 0.833333vw;
    transition: 0.5s;
    cursor: pointer;
}

.subscribe_btn a:hover{
background: #E42956;
}

.subscribe_btn a::before {
    font-family: FontAwesome;
    content: "\f053";
    position: absolute;
    right: 0.520833vw;
    top: 0.677083vw;
    pointer-events: none;
    border-radius: 50%;
    transform: rotateZ(180deg);
    font-size: 0.625vw;
}





@media screen and (max-width: 1366px){

    .subscribe_form_main {
        width: 35.25vw;
    }

}


@media screen and (max-width: 1024px){

.subscribe_form_main {
    width: 50.25vw;
}

.subscribe_form_main h2 {
    font-size: 1.9625vw;
}

.cntent_subscribe p {
    font-size: 1.433333vw;
}

.cntent_subscribe {
    gap: 0.460416vw;
    margin-top: 2.5625vw;
}

.subscribe_btn a {
    padding: 0.616666vw 4.645833vw;
    font-size: 1.433333vw;
}

.subscribe_btn a::before {
    right: 0.820833vw;
    top: 1.277083vw;
    font-size: 1.025vw;
}


}

@media screen and (max-width: 991px){


.subscribe_form_main {
    padding: 3.5625vw;
    padding-bottom: 4.5625vw;
}

.subscribe_check, .subscribe_failed{
    width: 4.604166vw;
    height: 4.604166vw;
    font-size: 2.041666vw;
}

.subscribe_form_main h2 {
    font-size: 2.2625vw;
}

.cntent_subscribe p {
    font-size: 1.833333vw;
}

.subscribe_btn a {
    padding: 0.816666vw 6.645833vw;
    font-size: 1.633333vw;
}

.subscribe_btn a::before {
    right: 1.120833vw;
    top: 1.377083vw;
    font-size: 1.225vw;
}


}




@media screen and (max-width: 767px){


.subscribe_form_main {
    width: 80.25vw;
}

.subscribe_form_main {
    padding: 6.5625vw;
    padding-bottom: 7.5625vw;
}

.subscribe_check, .subscribe_failed {
    width: 7.604166vw;
    height: 7.604166vw;
    font-size: 3.441666vw;
}

.subscribe_form_main h2 {
    font-size: 4.2625vw;
    margin-top: 3.302083vw;
}



.cntent_subscribe {
    gap: 0.960416vw;
    margin-top: 6.5625vw;
}

.cntent_subscribe p {
    font-size: 3.133333vw;
}

.subscribe_btn {
    margin-top: 7.083333vw;
}

.subscribe_btn a {
    padding: 1.816666vw 14.645833vw;
    font-size: 3.633333vw;
}

.subscribe_btn a::before {
    right: 2.520833vw;
    top: 3.177083vw;
    font-size: 2.825vw;
}



}

